import React, { Component } from "react";
import Joi from 'joi'


export default class FormRichiestaLibro extends Component {
   state = {
    richiestaLibro: {
      nome: "",
      titolo: "",
    },
    errors: {},
    successMessage: "",
  };


  schema = Joi.object({
    nome: Joi.string().required().empty().label('Inserisci nome e cognome'),
    titolo: Joi.string().required().empty().label('Inserisci il titolo del libro'),
  })

  handleChange = (event) => {
    const isChecked = event.target.type === "checkbox";
    this.setState({
      richiestaLibro : { ...this.state.richiestaLibro, [event.target.name]: isChecked
        ? event.target.checked
        : event.target.value,}
    });
  };

  validate = () => {
    const { error } = this.schema.validate(this.state.richiestaLibro, { abortEarly: false })
      return error ? Object.fromEntries(error.details.map(error => [error.context.key , error.context.label])) : {}
  }

  encode = (data) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }

  handleSubmit = (event) => {
    event.preventDefault();

    const errors = this.validate()
    this.setState({errors: errors})

    if (Object.keys(errors).length === 0) {

      fetch("/spazio-soci", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: this.encode({ "form-name": "prestito", ...this.state.richiestaLibro })
      })
      .then((data) => {
          const res = `Grazie ${this.state.richiestaLibro.nome}, 
                la tua richiesta di prestito è stata inviata a Luigina.`
              this.setState((prevState) => ({
                  ...prevState,
                  richiestaLibro:  {
                    nome: "",
                    titolo: "",
                  },
                  successMessage: res,
              }));
            })
      .catch(function (err) {
          const errMsg = `Richiesta non inviata per un errore di sistema, riprova`
          this.setState((prevState) => ({
            ...prevState,
            successMessage: errMsg,
        }));
      });
    } 
  }

  render() {
    const {
      richiestaLibro,
      errors,
      successMessage,
    } = this.state;

    return (
      <>
        <form data-netlify="true" data-netlify-honeypot="bot-field" name="prestito" onSubmit={this.handleSubmit} method='POST'>
          <label htmlFor='nome'>nome e cognome</label>
          <input
            id='nome'
            name='nome'
            type='text'
            value={richiestaLibro.nome}
            onChange={this.handleChange}
          />
          <span>{errors.nome}</span>
          <label htmlFor='titolo'>titolo del libro</label>
          <input
            id='titolo'
            name='titolo'
            type='text'
            value={richiestaLibro.titolo}
            onChange={this.handleChange}
          />
          <span>{errors.titolo}</span>
          <input
            type='hidden'
            name='form-name'
            value='prestito'
          />
          {!successMessage && (<button type='submit'>
            richiedi in prestito
          </button> )}
        {successMessage && (
          <span className="form-sent">
            {successMessage}
          </span>
        )}
        </form>
      </>
    );
  }
}

