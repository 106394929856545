/* eslint-disable react/prop-types */
/* eslint-disable react/no-children-prop */
import React from "react"
import styled from "styled-components/macro"

import { QUERY, COLORS } from "../../constants"

import FormRichiestaLibro from "../FormRichiestaLibro"

const LibroRequestForm = () => {
  return (
    <FormWrapper>
      <Titolo>Puoi richiedere un libro in prestito qui</Titolo>
      <FormRichiestaLibro />
    </FormWrapper>
  )
}

const FormWrapper = styled.div`
  margin-top: 2rem;
  background: ${COLORS.blue};
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 350px;
  min-height: 300px;
  width: 100%;
  padding: 4rem 2rem 3rem;
  @media ${QUERY.small} {
    min-width: 250px;
    min-height: 250px;
    padding: 1.5rem;
    margin-top: 2rem;
  }
  @media ${QUERY.smallest} {
    padding: 1rem;
  }
`
const Titolo = styled.span`
  text-transform: uppercase;
  font-size: calc(1.5 * var(--baseline-size));
  font-weight: 700;
  margin-bottom: 2rem;

  @media ${QUERY.medium} {
    font-size: calc(1.3 * var(--baseline-size));
  }

  @media ${QUERY.smaller} {
    font-size: calc(1.2 * var(--baseline-size));
  }
`

export default LibroRequestForm
