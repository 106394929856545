/* eslint-disable react/no-children-prop */
import React from "react"
import styled from "styled-components/macro"
import { useStaticQuery, graphql } from "gatsby"

import ArticoloCard from "../ArticoloCard"

import { QUERY, COLORS } from "../../constants"

const Articoli = () => {
  const data = useStaticQuery(graphql`
    query ArticoliQuery {
      allStrapiArticoli {
        edges {
          node {
            titolo
            url
          }
        }
      }
    }
  `)

  const listaArticoli = data.allStrapiArticoli.edges
  const articoli = listaArticoli.map(({ node: { id, titolo, url } }) => (
    <ArticoloCard key={id} titolo={titolo} url={url} />
  ))

  return (
    <>
      <MainTitle>Articoli</MainTitle>
      <ListaArticoli>{articoli}</ListaArticoli>
    </>
  )
}

const MainTitle = styled.h1`
  margin-top: 4rem;
  color: ${COLORS.red};
  padding: 2rem 4.5rem;
  margin-bottom: 2rem;
  font-size: calc(3.2 * var(--baseline-size));
  font-weight: 100;
  text-transform: uppercase;

  @media ${QUERY.medium} {
    font-size: calc(2.2 * var(--baseline-size));
  }

  @media ${QUERY.small} {
    font-size: calc(1.8 * var(--baseline-size));
    margin-bottom: 0;
  }

  @media ${QUERY.small} {
    padding: 2.5rem;
  }

  @media ${QUERY.smaller} {
    padding: 1.5rem;
  }

  @media ${QUERY.smallest} {
    padding: 1rem;
  }
`

const ListaArticoli = styled.section`
  padding: 4rem;
  columns: 2;

  @media ${QUERY.small} {
    padding: 2rem;
  }

  @media ${QUERY.smaller} {
    padding: 1rem;
    columns: 1;
  }

  @media ${QUERY.smallest} {
    padding: 0.5rem;
  }
`

export default Articoli
