/* eslint-disable react/no-children-prop */
import React from "react"
import styled from "styled-components/macro"
import { useStaticQuery, graphql } from "gatsby"

import LibroCard from "../LibroCard"

import { QUERY, COLORS } from "../../constants"

const TestiTaiChi = () => {
  const data = useStaticQuery(graphql`
    query TestiTaiChiQuery {
      allRestApiApiTestiSulTaiChis {
        edges {
          node {
            data {
              attributes {
                autore
                editore
                stato
                titolo
              }
            }
          }
        }
      }
    }
  `)

  const listaAltriTesti = data.allRestApiApiTestiSulTaiChis.edges[0].node.data
  const libri = listaAltriTesti.map(
    ({ attributes: { titolo, autore, editore, stato } }, idx) => (
      <LibroCard
        key={idx}
        titolo={titolo}
        autore={autore}
        editore={editore}
        stato={stato}
      />
    )
  )

  return (
    <Wrapper>
      <MainTitle>testi sul tai chi chuan</MainTitle>
      <ListaTesti>{libri}</ListaTesti>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  background: ${COLORS.lightBlue};
  padding: 4rem;

  @media ${QUERY.small} {
    padding: 2rem;
  }

  @media ${QUERY.smaller} {
    padding: 1rem;
  }

  @media ${QUERY.smallest} {
    padding: 0.5rem;
  }
`

const ListaTesti = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(380px, auto));
  gap: 2rem;

  @media ${QUERY.medium} {
    gap: 1.5rem;
  }

  @media ${QUERY.smaller} {
    grid-template-columns: repeat(auto-fit, minmax(280px, auto));
    gap: 1rem;
  }
`

const MainTitle = styled.h1`
  color: ${COLORS.red};
  padding: 2rem 0.5rem;
  margin-bottom: 2rem;
  font-size: calc(3.2 * var(--baseline-size));
  font-weight: 100;
  text-transform: uppercase;

  @media ${QUERY.medium} {
    font-size: calc(2.2 * var(--baseline-size));
  }

  @media ${QUERY.small} {
    font-size: calc(1.8 * var(--baseline-size));
    margin-bottom: 0;
  }
`

export default TestiTaiChi
