/* eslint-disable react/no-children-prop */
import React from "react"
import styled from "styled-components/macro"
import { useStaticQuery, graphql } from "gatsby"

import DispensaCard from "../DispensaCard"

import { QUERY, COLORS } from "../../constants"

const Dispense = () => {
  const data = useStaticQuery(graphql`
    query DispenseQuery {
      allStrapiDispense {
        edges {
          node {
            titolo
            file {
              file {
                publicURL
              }
            }
          }
        }
      }
    }
  `)

  const listaAltriTesti = data.allStrapiDispense.edges
  const dispense = listaAltriTesti.map(
    ({
      node: {
        id,
        titolo,
        file: {
          file: { publicURL },
        },
      },
    }) => <DispensaCard key={id} titolo={titolo} url={publicURL} />
  )

  return (
    <Wrapper>
      <MainTitle>Dispense</MainTitle>
      <ListaDispense>{dispense}</ListaDispense>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  background: ${COLORS.lightBlue};
  padding: 4rem;

  @media ${QUERY.small} {
    padding: 2rem;
  }

  @media ${QUERY.smaller} {
    padding: 1rem;
  }

  @media ${QUERY.smallest} {
    padding: 0.5rem;
  }
`

const ListaDispense = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(380px, auto));
  gap: 2rem;

  @media ${QUERY.medium} {
    grid-template-columns: repeat(auto-fit, minmax(350px, auto));
    gap: 1.5rem;
  }

  @media ${QUERY.smaller} {
    grid-template-columns: repeat(auto-fit, minmax(250px, auto));
    gap: 1rem;
  }
`

const MainTitle = styled.h1`
  color: ${COLORS.red};
  padding: 2rem 0.5rem;
  margin-bottom: 6rem;
  font-size: calc(3.2 * var(--baseline-size));
  font-weight: 100;
  text-transform: uppercase;

  @media ${QUERY.medium} {
    font-size: calc(2.2 * var(--baseline-size));
  }

  @media ${QUERY.small} {
    font-size: calc(1.8 * var(--baseline-size));
    margin-bottom: 2rem;
  }
`

export default Dispense
