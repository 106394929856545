import * as React from "react"
import { Helmet } from "react-helmet"
import AltriTesti from "../../components/AltriTesti"
import TestiTaiChi from "../../components/TestiTaiChi"
import HeaderSpazio from "../../components/HeaderSpazio/HeaderSpazio"
import Dispense from "../../components/Dispense"
import Articoli from "../../components/Articoli"
import LibroRequestForm from "../../components/LibroRequestForm/LibroRequestForm"
import FooterSpazio from "../../components/FooterSpazio"
import Navigator from "../../components/Navigator"

import FontStyles from "../../components/FontStyles"
import GlobalStyles from "../../components/GlobalStyles"

const Biblioteca = () => (
  <>
    <FontStyles />
    <GlobalStyles />
    <Helmet>
      <meta name={`robots`} content={`noindex, nofollow`} />
    </Helmet>
    <HeaderSpazio />
    <Navigator />
    <TestiTaiChi />
    <AltriTesti />
    <LibroRequestForm />
    <Dispense />
    <Articoli />
    <FooterSpazio />
  </>
)

export default Biblioteca
