/* eslint-disable react/no-children-prop */
import React from "react"
import styled from "styled-components/macro"
import { useStaticQuery, graphql } from "gatsby"

import LibroCard from "../LibroCard"
import LibriSwiper from "../LibriSwiper"

import { QUERY, COLORS } from "../../constants"

const AltriTesti = () => {
  const data = useStaticQuery(graphql`
    query AltriTestiQuery {
      allStrapiAltriTesti {
        edges {
          node {
            id
            titolo
            autore
            editore
            stato
          }
        }
      }
    }
  `)

  const listaAltriTesti = data.allStrapiAltriTesti.edges
  const libri = listaAltriTesti.map(
    ({ node: { id, titolo, autore, editore, stato } }) => (
      <LibroCard
        key={id}
        titolo={titolo}
        autore={autore}
        editore={editore}
        stato={stato}
      />
    )
  )

  return (
    <>
      <MainTitle>Altri testi</MainTitle>
      <LibriSwiper slides={libri} />
    </>
  )
}

const MainTitle = styled.h1`
  margin-top: 4rem;
  color: ${COLORS.red};
  padding: 2rem 4.5rem;
  margin-bottom: 2rem;
  font-size: calc(3.2 * var(--baseline-size));
  font-weight: 100;
  text-transform: uppercase;

  @media ${QUERY.medium} {
    font-size: calc(2.2 * var(--baseline-size));
  }

  @media ${QUERY.small} {
    font-size: calc(1.8 * var(--baseline-size));
    margin-bottom: 0;
  }

  @media ${QUERY.small} {
    padding: 2.5rem;
  }

  @media ${QUERY.smaller} {
    padding: 1.5rem;
  }

  @media ${QUERY.smallest} {
    padding: 1rem;
  }
`

export default AltriTesti
