/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-children-prop */
import React from "react"
import styled from "styled-components/macro"

import { QUERY, COLORS } from "../../constants"

const ArticoloCard = ({ titolo, url }) => {
  return (
    <Linky href={url} target="_blank" rel="noopener noreferrer">
      <Titolo>{titolo}</Titolo>
    </Linky>
  )
}

const Titolo = styled.div`
  break-inside: avoid;
  text-transform: uppercase;
  font-size: calc(1.1 * var(--baseline-size));
  padding: 2rem 3rem;
  background: ${COLORS.blue};
  border-radius: 30px;

  @media ${QUERY.medium} {
    font-size: calc(1 * var(--baseline-size));
    padding: 2rem;
  }

  @media ${QUERY.smallest} {
    font-size: calc(1 * var(--baseline-size));
    padding: 1.5rem;
  }
`

const Linky = styled.a`
  color: ${COLORS.black};
  text-decoration: none;
  font-weight: 100;
  padding: 0.5rem;
`

export default ArticoloCard
