/* eslint-disable react/prop-types */
/* eslint-disable react/no-children-prop */
import React from "react"
import styled from "styled-components/macro"

import { QUERY, COLORS } from "../../constants"

const LibroCard = ({ titolo, autore, editore, stato }) => {
  return (
    <Wrapper>
      <Titolo>{titolo}</Titolo>
      <Autore>{autore}</Autore>
      <Editore>{editore}</Editore>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  min-width: 350px;
  width: 100%;
  padding: 2rem 3rem;
  border-radius: 30px;
  border: 1px solid ${COLORS.blue};
  background: ${COLORS.white};

  @media ${QUERY.small} {
    min-width: 250px;
    padding: 2rem;
  }
  
  @media ${QUERY.smallest} {
    margin-top: 3rem;
    padding: 1.5rem;
  }
`

const Titolo = styled.span`
  text-transform: uppercase;
  font-size: calc(1.5 * var(--baseline-size));
  font-weight: 700;
  margin-bottom: 2rem;

  @media ${QUERY.medium} {
    font-size: calc(1.3 * var(--baseline-size));
  }

  @media ${QUERY.smaller} {
    font-size: calc(1.2 * var(--baseline-size));
  }
`

const Autore = styled.span`
  font-size: calc(1.4 * var(--baseline-size));
  font-weight: 100;
  margin-bottom: 0.2rem;

  @media ${QUERY.smaller} {
    font-size: calc(1.2 * var(--baseline-size));
  }
`

const Editore = styled.span`
  text-transform: uppercase;
  font-size: calc(1.1 * var(--baseline-size));
  font-weight: 100;

  @media ${QUERY.smaller} {
    font-size: calc(1 * var(--baseline-size));
  }
`

export default LibroCard
